body {
  padding: 0px;
  margin: 0px;
}

.parallax {
  /* fill up 100vh */
  height: 100vh;

  /* parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.top-parallax {
  background-color: white;
  height: 100%;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /*following just for demo purposes to add height and top padding*/
  padding-top: 95vh;
}

.parallax-curtain {
  height: 100vh;
}

html,
body {
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
}

.termsContainer {
  width: 93%;
  margin: 60px auto 0;
}



.termsHeading {
  font-size: 18px;
  margin: 30px 0 10px;
}

.termsHeading+p {
  padding-left: 10px;

}

.outerList li {
  list-style: none;
  margin: 10px 0;
}

.innerList li {
  list-style: disc;
  margin: 10px 0;
}

.MuiTypography-root {
  color: white !important;
}


.footerLink {
  color: white;
  text-decoration: none;
}


.contactContainer {
  position: relative;
  height: 100vh;
}

.containerBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.03);
  padding: 150px 80px;
}


.containerBox h1 {
  font-size: 34px;
  line-height: 20px;
}


.containerBox p {
  text-align: center;
  font-size: 20px;
  /* line-height: 10px; */
}

.email {
  font-size: 18px !important;
}

.navigation {
  position: fixed;
  top: 20px;
  right: 5px;
  padding: 16;
  z-index: 9999;
  display: flex;
  gap: 0.5rem;
}

@media screen and (max-width: 768px) {
  .termsContainer {
    margin: 80px auto 0;
  }
  .navigation{
    position: relative;
    top: 65px;
    text-align: center;
    display: block;
  }
  .navigation a{
    margin-bottom: 10px;
  }
.containerBox {
    position: relative;
   top: 40%; 
   padding: 150px 25px;
  }
  .containerBox h1{
    font-size: 20px;
  }
  .containerBox p{
    font-size: 16px;
  }
  .email{
    font-size: 16px !important;
  }

}